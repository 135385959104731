pre {
  display: grid;
  white-space: wrap; }
// code
//   white-space: none
.highlight {
  margin: 1.5rem 0 2.5rem;
  padding: 0 !important;
  position: relative;
  &:not(.sans)::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    left: 3rem;
    background: var(--bg);
    z-index: 10; }
  & & {
    margin: 0; }

  pre {
    padding: 1rem;
    background: var(--accent) !important;
    color: var(--text) !important;
    border-radius: 4px;
    max-width: 100%;
    overflow-x: auto; }

  table {
    display: grid; }

  .lntd {
    width: 100%;
    border: none;
    &:first-child {
      &, pre {
        width: 2.5rem !important;
        padding-left: 0;
        padding-right: 0;
        color: var(--light) !important;
        background-color: var(--theme) !important;
        border-radius: 0.25rem 0 0 0.25rem; }

      pre {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column; } }

    &:last-child {
      padding-left: 0; } } }

.err {
  color: #a61717;
  background-color: #e3d2d2; }

.hl {
  display: block;
  width: 100%;
  background-color: #ffffcc; }

.ln {
  padding: 0 2em 0 0;
  color: var(--theme);
  position: sticky;
  left: 0;
  background: var(--accent);
  z-index: 9; }

.k, .kc, .kd, .kn, .kp, .kr, .kt, .nt {
  color: #6ab825;
  font-weight: 500; }

.kn , .kp {
  font-weight: 400; }

.nb, .no, .nv {
  color: #24909d; }

.nc, .nf, .nn {
  color: #447fcf; }

.s, .sa, .sb, .sc, .dl, .sd, .s2, .se, .sh, .si, .sx, .sr, .s1, .ss {
  color: #ed9d13; }

.m, .mb, .mf, .mh, .mi, .il, .mo {
  color: #3677a9; }

.ow {
  color: #6ab825;
  font-weight: 500; }

.c, .ch, .cm, .c1 {
  color: #999;
  font-style: italic; }

.cs {
  color: #e50808;
  background-color: #520000;
  font-weight: 500; }

.cp, .cpf {
  color: #cd2828;
  font-weight: 500; }

.gd, .gr {
  color: #d22323; }

.ge {
  font-style: italic; }

.gh, .gu, .nd, .na, .ne {
  color: #ffa500;
  font-weight: 500; }

.gi {
  color: #589819; }

.go {
  color: #ccc; }

.gp {
  color: #aaa; }

.gs {
  font-weight: 500; }

.gt {
  color: #d22323; }

.w {
  color: #666; }
