.pale {
  opacity: 0.7; }

.hidden {
  display: none; }

.center {
  text-align: center;
  margin: 5px auto; }

.left {
  text-align: left; }

.link {
  display: inline-flex;
  align-items: center;
  width: 2.5rem;
  margin: 0 0.25rem;
  padding: 0 0.25rem;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--theme); }
  &_owner:hover & {
    opacity: 0.9; } }

.wrap {
  max-width: 1024px;
  &, & {
    padding: 0 25px;
    margin: 0 auto; } }

@for $i from 1 through 2 {
  $size: $i * 1.5rem;
  $x-size: $size * 0.5;
  .pt-#{$i} {
    padding-top: $size; }

  .pb-#{$i} {
    padding-bottom: $size; }

  .mt-#{$i} {
    margin-top: $size; }

  .mb-#{$i} {
    margin-bottom: $size; } }

%grid {
  display: grid;
  grid-template-columns: 1fr; }

[class*='grid-'] {
  grid-gap: 2rem; }

.grid-2, .grid-3, .grid-4, .grid-auto, .grid-reverse {
  @extend %grid; }

@media screen and (min-width: 42rem) {
  .grid-auto {
    grid-template-columns: 1fr 3fr; }

  .grid-reverse {
    // flip grid auto
    grid-template-columns: 3fr 1fr; }

  .grid-2 {
    grid-template-columns: 1fr 1fr; }

  .grid-3 {
    grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr)); }

  .grid-4 {
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr)); } }

.upcase {
  text-transform: uppercase; }


.attribution {
  opacity: 0.33;
  font-size: 0.8rem;
  transition: opacity 0.25 var(--ease);
  &:hover {
    opacity: 1; } }

.mt {
  margin-top: 5rem; }

.never {
  min-height: 60vh;
  background: url($never-icon-path);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0 1.5rem;
  margin-top: 6rem;
  &_title {
    text-align: left; } }

.icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--theme);
  transform-origin: 50% 50%;
  &_scale {
    transform: scale(0.50); }
  &_invert {
    transform: scale(0.50) rotate(180deg); } }
