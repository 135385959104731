@keyframes showMenu {
  0% {
    top: -100%;
    opacity: 0; }

  100% {
    top: 0;
    opacity: 1; } }

@keyframes hideMenu {
  0% {
    top: 0;
    opacity: 1; }
  100% {
    top: -100%;
    opacity: 0; } }
